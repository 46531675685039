.App {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: scroll;

  --dark-blue: #12232E;
  --white: #FCF6F5FF;
  --indigo: #1E2761;
  --sky-blue: #89ABE3FF;
  --coral: #FC766AFF;
}
