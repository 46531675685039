.SignUp_Page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.SignUp_Page_content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}
