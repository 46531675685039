.Header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    background-color: var(--dark-blue, darkblue);
    color: var(--white);

    position: sticky;
    top: 0;
    z-index: 9999;
}

.Header_center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 1em;
}
.Header_center img {
    height: 48px;
    width: auto;
}

